import { initCustomElements } from './initCustomElements'
import wixCustomElementsRegistry from '@wix/wix-custom-elements'

const initWixCustomElementsRegistry = () => {
	const resizeService = {
		init: (callback: any) => new ResizeObserver(callback),
	}

	const windowResizeService = {
		init: (callback: any) => window.addEventListener('resize', callback),
	}

	return wixCustomElementsRegistry.init({ resizeService, windowResizeService })
}

const wixCustomElements = initWixCustomElementsRegistry()
initCustomElements(window.viewerModel, wixCustomElements)
